<i18n>
{
  "en": {
    "movie-count": "Featured movies",
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "movie_count": "# Movies",
    "no_movies": "You have no download history"
  },
  "ja": {
    "movie-count": "出演作品数",
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "movie_count": "出演作品数",
    "no_movies": "ダウンロード履歴がありません"
  }
}
</i18n>

<template>
<div class="section">
  <!-- no history: show message -->
  <div v-if="historyList.length === 0">
    <p class="is-note">{{ $t('no_movies') }}</p>
  </div>
  <!-- has history: show movie list -->
  <div v-if="historyList.length > 0" class="grid is-history">
    <div class="grid-item" v-for="(movie, index) in historyList" :key="movie.MovieID">
      <router-link :to="`/movies/${movie.MovieID}/`" class="entry">
        <div class="entry-media">
          <div v-if="movie.isVIP" class="tag-member is-vip">{{ $t('vip_badge') }}</div>
          <div v-if="movie.isSVIP" class="tag-member is-svip">{{ $t('svip_badge') }}</div>
          <div v-if="movie.isAnnual" class="tag-member is-annual">{{ $t('annual_badge') }}</div>
          <div class="media-thum">
            <img :src="`${movie.thumbnailSrc}`" @error="useFallbackImage(index)" class="media-image" loading="lazy" />
          </div>
        </div>
        <div class="entry-meta">
          <div class="meta-data">{{ movie.ReleaseDate }}</div>
          <!-- multiple actress names spacing -> jp: space / en: comma + space -->
          <!-- need 2 x '&#32;' to get 1 space -->
          <div class="meta-title" v-if="locale == 'ja'"><span v-for="(actress, index) in movie.ActressesJa" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesJa.length">&#32;&#32;</span></span></div>
          <div class="meta-title" v-if="locale == 'en'"><span v-for="(actress, index) in movie.ActressesEn" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesEn.length">,&#32;</span></span></div>
          <!-- only display age when there is only 1 actress (if actressAge is computed and set in historyList below) -->
          <div class="meta-data" v-if="movie.actressAge"><span v-if="locale == 'en'">Age </span>{{ movie.actressAge }}<span v-if="locale == 'ja'">歳</span></div>
          <div class="meta-data" v-else>---</div>
        </div>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-shadow: 0 */
import axios from 'axios';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import Limiter from '@/assets/js/utils/limiters';

export default {
  props: {
    listType: {
      type: String,
      default: 'today',
    },
  },
  data() {
    return {
      historyList: [],
      posterFallbackTries: {},
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  created() {
    // call DML API and update state values
    Limiter.throttle(axios.get(`${process.env.VUE_APP_DML_API}/json/history`, { withCredentials: true }).then((response) => {
      if (response.data !== null) {
        // dml api json response doesn't contain the 'Downloaded' property if there hasn't been any
        // downloaded movies, so we have to add an extra check to see if the prop exists
        let dmlHistoryList = [];
        if (this.listType === 'yesterday' && Object.prototype.hasOwnProperty.call(response.data.Previous, 'Downloaded')) {
          dmlHistoryList = response.data.Previous.Downloaded;
        } else if (this.listType === 'today' && Object.prototype.hasOwnProperty.call(response.data.Current, 'Downloaded')) {
          dmlHistoryList = response.data.Current.Downloaded;
        }

        // dml api only returns japanese movie data; so, get movie details for each movie in the
        // list, so we can get all the data (english!) that we normally have for movie lists
        const bfAPI = new BifrostAPI();
        if (dmlHistoryList.length > 0) {
          dmlHistoryList.forEach((movie) => {
            bfAPI.getMovieDetail(movie.MovieID).then((result) => {
              const details = MovieHelper.expandDetails(result, this.locale, this.userTypeID);
              this.historyList.push(details);

              // check actress age
              // hide age if there are more than 1 actress in actressList
              // only display age if it's set over '18' (prevent webmaster input error and ignore data of age'0' in db)
              for (let i = 0; i < this.historyList.length; i += 1) {
                if (this.historyList[i].ActressesList && Object.keys(this.historyList[i].ActressesList).length === 1) {
                  const firstActressID = Object.keys(this.historyList[i].ActressesList)[0];
                  if (this.historyList[i].ActressesList[firstActressID].Age
                      && this.historyList[i].ActressesList[firstActressID].Age >= 18) {
                    this.$set(this.historyList[i], 'actressAge', this.historyList[i].ActressesList[firstActressID].Age);
                  }
                }
              }
            });
          });
        }
      }
    }).catch((error) => {
      console.log('%cDML API error', 'background: #222; color: #f00', error);
    }), 5000);
  },
  methods: {
    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTries[this.historyList[index].MovieID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(this.historyList[index], 'thumbnailSrc', `/assets/sample/${this.historyList[index].MovieID}/list1.jpg`);
        this.posterFallbackTries[this.historyList[index].MovieID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(this.historyList[index], 'thumbnailSrc', '/static/images/no-image.png');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
