<template>
  <div>
    <div v-for="(banners, groupName) in badminBannerGroups" :key="groupName">
      <!-- fixed banner: full width -->
      <div v-if="banners[0].banner_type == 'full_width'">
        <div
          class="banner banner-layered"
          :style="{ 'background-image': 'url(/dyn/dla/images/' + banners[1].img_path + ')' }"
        >
          <div>
            <img
              class="banner-image"
              :src="`/dyn/dla/images/${banners[0].img_path}`"
              @click="copyToClipboard(banners[0].coupon_code)"
            />
          </div>
        </div>
      </div>

      <!-- fixed banner: responsive -->
      <div v-if="banners[0].banner_type == 'responsive'">
        <div class="banner">
          <picture>
            <source
              media="(max-width: 896px)"
              :srcset="`/dyn/dla/images/${banners[1].img_path}`"
            />
            <source
              media="(min-width: 897px)"
              :srcset="`/dyn/dla/images/${banners[0].img_path}`"
            />
            <img
              :src="`/dyn/dla/images/${banners[0].img_path}`"
              class="banner-image"
              @click="copyToClipboard(banners[0].coupon_code)"
            />
          </picture>
        </div>
      </div>

      <!-- fixed banner -->
      <div v-if="banners[0].banner_type == 'fixed'">
        <div class="banner" v-for="banner in banners" :key="banner.display_order">
          <img
            :src="`/dyn/dla/images/${banner.img_path}`"
            class="banner-image"
            @click="copyToClipboard(banner.coupon_code)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Badmin from '@/assets/js/services/Badmin';
import EventBus from '@/assets/js/utils/EventBus';

export default {
  data() {
    return {
      badminBannerGroups: {},
    };
  },
  async beforeCreate() {
    const badminSvc = new Badmin(
      this.$i18n.locale,
      this.$store.getters['user/typeID'],
    );
    this.badminBannerGroups = await badminSvc.getBanners('ddl');
  },
  methods: {
    copyToClipboard(msg) {
      if (msg && msg !== '') {
        this.$copyText(msg).then(
          () => {
            // copy succesful
            console.log('%ccopied to clipboard', 'color:#f0f', msg);
            EventBus.$emit('notification:event:generic', {
              text: this.$t('code_copied'),
              duration: 3000,
            });
          },
          (e) => {
            console.log('%c copyToClipboard failed!', 'color:#f00', e);
          },
        );
      }
    },
  },
  computed: {
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    async userTypeID(newVal, oldVal) {
      if (newVal !== oldVal) {
        const badminSvc = new Badmin(
          this.$i18n.locale,
          this.$store.getters['user/typeID'],
        );
        this.badminBannerGroups = await badminSvc.getBanners('ddl');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
