<i18n>
{
  "en": {
    "browser_title": "Daily Download Limit Plan"
  },
  "ja": {
    "browser_title": "ダウンロード増量プラン"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div class="dml" v-if="!newXPEligible">
      <dml-image />

      <!-- japanese text -->
      <section v-if="locale == 'ja'">
        <div class="contents">
          <div class="heading">
            <h2>ダウンロード増量プランとは</h2>
          </div>
          <p>ダウンロード増量プランとは、ダウンロード本数を追加購入することにより、1日あたりのダウンロード上限を大幅UPさせる定額サービスです。</p>
          <ul class="list-style list-style--disc">
            <li>30本増量プラン (30日間 / 1日1本増)</li>
            <li>60本増量プラン (30日間 / 1日2本増)</li>
            <li>90本増量プラン (30日間 / 1日3本増)</li>
            <li>300本増量プラン (30日間 / 1日10本増)</li>
          </ul>
          <p>の全4プランの中から好みに合わせたプランをお選びいただけます。</p>
        </div>
      </section>
      <section v-if="locale == 'ja'">
        <div class="contents">
          <div class="heading">
            <h2>プラン比較</h2>
          </div>
          <div class="dml-comparison">
            <div class="comparison-description">
              <p>ダウンロード増量プランをご購入いただくことにより、30本増量プランで最大1.25倍、60本増量プランで最大1.5倍、90本増量プランで最大1.75倍、300本増量プランで最大3.5倍まで1日のダウンロード上限を引き上げることができ、サイトをよりお楽しみいただけます。</p>
            </div>
            <div class="comparison-figure">
              <div class="figure-item">
                <div class="figure-graph figure-graph--unlimited">
                  <span class="figure-note">3.5x</span>
                </div>
                <div class="figure-title">300本増量プラン</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--90">
                  <span class="figure-note">1.75x</span>
                </div>
                <div class="figure-title">90本増量プラン</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--60">
                  <span class="figure-note">1.5x</span>
                </div>
                <div class="figure-title">60本増量プラン</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--30">
                  <span class="figure-note">1.25x</span>
                </div>
                <div class="figure-title">30本増量プラン</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--0"></div>
                <div class="figure-title">増量プランなし</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="locale == 'ja'">
        <div class="contents">
          <div class="heading">
            <h2>お申し込み</h2>
          </div>
          <package-table currency="USD" package-type="dml" />
          <ul class="list-style list-style--disc">
            <li>ダウンロード増量プランの有効期限は、一本道の会員期間終了に伴い自動で終了します。</li>
            <li>ダウンロード増量プランは自動継続方式となっておりますので、30日ごとに自動課金されます。</li>
            <li>
              ダウンロード増量プランの退会をご希望の場合はD2Passマイアカウントからの退会手続きが必要です。退会手続きは
              <a
                href="https://www.d2pass.com/account/download/sitelist?NetiFL=1"
                target="_blank"
                rel="noopener"
              >こちら »</a>
            </li>
            <li>ダウンロード増量プランの購入後、サイト上で有効になるまで数分かかることがありますので予めご了承ください。</li>
          </ul>
        </div>
      </section>
      <!-- /japanese text -->

      <!-- english text -->
      <section v-if="locale == 'en'">
        <div class="contents">
          <div class="heading">
            <h2>About Daily Download Limit Plan</h2>
          </div>
          <p>Daily Download Limit Plan is a service that increases the maximum number of movies you can download per day by purchasing additional amount. We currently offer following 4 plans.</p>
          <ul class="list-style list-style--disc">
            <li>+30 Movies Plan (30 days / +1 movies per day)</li>
            <li>+60 Movies Plan (30 days / +2 movies per day)</li>
            <li>+90 Movies Plan (30 days / +3 movies per day)</li>
            <li>+300 Movies Plan (30 days / +10 movies per day)</li>
          </ul>
        </div>
      </section>
      <section v-if="locale == 'en'">
        <div class="contents">
          <div class="heading">
            <h2>Plan comparison</h2>
          </div>
          <div class="dml-comparison">
            <div class="comparison-description">
              <p>By purchasing the Daily Download Limit Plan, you can increase the download limit for 1 day up to x1.25 with +30 plan, up to x1.5 with +60 plan, up to x1.75 with +90 plan, and x3.5 with +300 plan.</p>
            </div>
            <div class="comparison-figure">
              <div class="figure-item">
                <div class="figure-graph figure-graph--unlimited">
                  <span class="figure-note">3.5x</span>
                </div>
                <div class="figure-title">+300 Movies Plan</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--90">
                  <span class="figure-note">1.75x</span>
                </div>
                <div class="figure-title">+90 Movies Plan</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--60">
                  <span class="figure-note">1.5x</span>
                </div>
                <div class="figure-title">+60 Movies Plan</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--30">
                  <span class="figure-note">1.25x</span>
                </div>
                <div class="figure-title">+30 Movies Plan</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--0"></div>
                <div class="figure-title">Default</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="locale == 'en'">
        <div class="contents">
          <div class="heading">
            <h2>Purchase</h2>
          </div>
          <package-table currency="USD" package-type="dml" />
          <ul class="list-style list-style--disc">
            <li>Daily Download Limit Plan will be automatically terminated when your 1pondo's membership expires.</li>
            <li>Daily Download Limit Plan will be automatically renewed and charged every 30 days.</li>
            <li>
              If you want to cancel your Daily Download Limit Plan, you will need to cancel from D2Pass My Account page.
              <a
                href="https://www.d2pass.com/account/download/sitelist?NetiFL=1&lang=en"
                target="_blank"
                rel="noopener"
              >
                Click here
                <i class="iconf-ex-link"></i>
              </a> to cancel.
            </li>
            <li>Please note that it may take several minutes to be activated on the site after purchase of the Daily Download Limit Plan.</li>
          </ul>
        </div>
      </section>
      <!-- /english text -->
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import DMLImage from '@/components/dml/dmlImage.vue';
import PackageTable from '@/components/dml/packageTable.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'dml-image': DMLImage,
    'package-table': PackageTable,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
  },
};
</script>

<style lang="scss">
</style>
