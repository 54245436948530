<i18n>
{
  "en": {
    "browser_title": "Download History",
    "heading": "Download History",
    "tab_today": "Today",
    "tab_yesterday": "Yesterday",
    "dml_title": "Today's Download",
    "dml_remaining_0": "Remaining: ",
    "dml_remaining_1": " ",
    "dml_separator": " / ",
    "dml_total_0": "Total: ",
    "dml_total_1": " ",
    "dml_link": "Add More Downloads"
  },
  "ja": {
    "browser_title": "ダウンロード履歴",
    "heading": "ダウンロード履歴",
    "tab_today": "本日の履歴",
    "tab_yesterday": "前日の履歴",
    "dml_title": "本日のダウンロード",
    "dml_remaining_0": "残り ",
    "dml_remaining_1": " ",
    "dml_separator": " / ",
    "dml_total_0": "全",
    "dml_total_1": "本",
    "dml_link": "ダウンロード本数を追加する"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />

  <div id="history" class="page-margin" v-if="!newXPEligible">
    <div class="block-header">
      <div class="container">
        <h1>{{ $t('heading') }}</h1>
      </div>
    </div>

    <div class="container">
      <!-- bar graph -->
      <div class="section is-wide is-slot-status">
        <div class="history-heading">
          <h3>{{ $t('dml_title') }}</h3>
          <div class="history-status">{{ $t('dml_total_0') }}{{ slotsTotal }}{{ $t('dml_total_1') }}{{ $t('dml_separator') }}{{ $t('dml_remaining_0') }}{{ slotsFree }}{{ $t('dml_remaining_1') }}</div>
        </div>
        <div class="history-slot">
          <div class="history-slot--used" :style="`width: ${dmlRatio}%;`"></div>
        </div>
        <!--<p><a href="/dml/">{{ $t('dml_link') }} <i class="iconf-keyboard-arrow-right is-right"></i></a></p>-->
      </div>

      <!-- tabs -->
      <vue-tabs type="pills" @tab-change="tabChangeHandler">
        <v-tab :title="$t('tab_today')">
          <history-list list-type="today" />
        </v-tab>
        <v-tab :title="$t('tab_yesterday')">
          <history-list list-type="yesterday" />
        </v-tab>
      </vue-tabs>
    </div>
  </div><!-- /#history -->
</main>
</template>

<script>
/* eslint max-len: 0 */
import { VueTabs, VTab } from 'vue-nav-tabs';
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import HistoryList from '@/components/downloadhistory/historyList.vue';

export default {
  components: {
    VueTabs,
    VTab,
    'vue-headful': vueHeadful,
    'history-list': HistoryList,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    // dml status
    this.$store.dispatch('dml/refreshStatus');
  },
  methods: {
    tabChangeHandler(tabIndex, newTab) {
      // using tab-change event available in vue-nav-tabs: http://vuejs.creative-tim.com/vue-tabs/#/
      this.$analytics.trackEvent('Download History', newTab.title);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    slotsUsed() {
      return this.$store.getters['dml/slotsUsed'];
    },
    slotsFree() {
      return this.$store.getters['dml/slotsFree'];
    },
    slotsTotal() {
      return this.slotsUsed + this.slotsFree;
    },
    dmlRatio() {
      const ratio = (this.slotsFree / this.slotsTotal) * 100;
      return 100 - Math.round(ratio); // remove the 100 subtraction if we want an empty bar to mean "no downloads left"
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_history.scss';
</style>
